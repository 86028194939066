import Splide from '@splidejs/splide';
// Default theme
import '@splidejs/splide/css';

// // or other themes
// import '@splidejs/splide/css/skyblue';
// import '@splidejs/splide/css/sea-green';

// or only core styles
import '@splidejs/splide/css/core';


let slideConfig = {
    arrows: false,
    type: 'loop',
    paginationKeyboard: false,
    pagination: true,
    cover: true,
    heightRatio: 0.7,
    drag: true,
    snap: true
}

let elms = document.getElementsByClassName('splide');

for (let i = 0; i < elms.length; i++) {
    new Splide(elms[i], slideConfig).mount();
}
